.studentAssignmentDetails_editor_container{
    padding: 40px;
    margin-top: 30px;
}

.approveDenyButtonsDiv{
    float: right;
    display: flex;
    margin-left: auto;
    margin-top: 15px;
}

/* Student Details New Top Section */

.leadsDetailsTopContainer{
    display: flex;
    justify-content: space-around;
    padding:5px;
    margin:10px;
    border-radius: 10px;
    background-color: indigo;
    /* margin-left: 12px; */
    
}

.studentDetailImage{
    max-width: 80px;
    margin-bottom: 15px;
}

.studentDetailsImageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.studentDetailsImageContainer span{
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    color:white
}


.studentDetailsTopInfoContainer{
    margin-left: 20px;
}

.userIdInfoDiv{
    display: flex;
    flex-direction: column;
    padding-left: 0px;
}

.studentDetailsInfoTopButtons{
    margin-bottom: 10px;
    border-bottom: 2px solid black;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;

}

.studentTopButtonsDiv{
    margin-left: 100px;
}

.studentTopButtonsDiv button{
    margin-right: 10px;
    font-size: 15px;
    border: none;
    padding: 10px 15px;
    background: #2ec2e2;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 5px;
}

.referralListButton{
    margin-right: 10px;
    font-size: 15px;
    border: none;
    padding: 10px 15px;
    background: #2ec2e2;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 5px;
}

.userIdInfoDiv span:first-child{
    color: red;
    font-size: 18px;
    font-weight: bold;
}

.userIdInfoDiv span:last-child{
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.studentDetailsTopInfoInnerContainer1{
    display: flex;
}

.studentDetailsTopInfoInnerContainer1 ul{
    list-style: none;
}

.studentDetailsTopInfoInnerContainer1 ul li{
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    color:white;
}

.studentDetailsTopInfoInnerContainer1 ul li span{
    color: white;
}
.steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .step {
    display: flex;
    gap:10;
    flex-direction: row;
    justify-content: center;
    width: calc(100%/8); 
    align-items: 'center';
    padding: 20px;
    background-color: blue;
    border: 1px solid black;
    border-radius: 5px;
  }
  
  .step-number {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .step-description {
    font-size: 16px;
    color: white;
  }
