.assignmentRemarksOptionContainer{
   display: flex;
   flex-wrap: wrap;
   margin-bottom: 15px;
}

.assignmentRemarksOptionDiv{
    border: 1px solid grey;
    border-radius: 3px;
    padding:0px 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 5px;
}