//
// Custom checkbox
//
.form-group{
    .rti--container {
        --rti-bg: #fff;
        --rti-border: #f5f5f5;
        --rti-main: #ececec;
        --rti-radius: .375rem;
        --rti-s: .5rem;
        --rti-tag: #edf2f7;
        --rti-tag-remove: #e53e3e;
        --rti-tag-padding: .15rem .25rem;
        align-items: center;
        background: var(--rti-bg);
        //border: 1px solid var(--rti-border);
        box-shadow: white;
        box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
        border-radius: var(--rti-radius);
        display: flex;
        flex-wrap: wrap;
        gap: var(--rti-s);
        line-height: 1.8;
        padding: var(--rti-s);
    }
    .rti--input{
        font-size:1rem;
        font-weight: 400;
        padding-right: 5px;
    }
}

.custom-checkbox {
    .custom-control-input ~ .custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}
