.communityFeedContainer{
    margin-top: 120px;
    min-height: 400px;
}

.communityPostsContainerMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* Scroll to top button */

.scrollButton {
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 500; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color:#f2f2f2; /* Set a background color */
    color: black; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
  }


  /* Filter Styling */

  .feedFilterContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .filterSearchDiv input{
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 10px;
  }

  .feedFilterButton{
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
    color:#2c3e50 ; 
  }
  
  .feedFilterButton.active{
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 50px;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #2ec2e2;
  }
