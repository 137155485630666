.commentCardContainer{
    border: 1px solid black;
    width: 100%;
    padding: 10px;
    display: flex;
    margin-top: 12px;
    border-radius: 3px;
}

.commentCardCommentInfoDiv{
    display: flex;
    width: 70%;

}

.commentCardContainerImageDiv_image{
    width: 30px;
    height: 30px;
}

.commentCardContainerImageDiv{

}

.commentCardCommentDetailsDiv{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    border-right: 1px solid black;
    width: 80%;

}

.commentCardUserName{
    margin-bottom: 10px;  
}

.commentCardLessonInfoDiv{
    display: flex;
    padding-left: 0px;
    width: 30%;
    
}

.commentCardActionButtonDiv{
    display: flex;
    justify-content: space-between;
}

.commentCardButtons{
    display: flex;
}

.commentCardButtons:first-child{
    margin-right: 10px;
}

.commentCardActionButtonDiv .fas.fa-check-circle{
    font-size: 28px;
    cursor: pointer;
    margin-right: 10px;
    color: green;
}

.commentCardActionButtonDiv .fas.fa-edit{
    font-size: 28px;
    cursor: pointer;
    margin-right: 10px;
    color: green;
}

.commentCardActionButtonDiv .fas.fa-times-circle{
    font-size: 28px;
    cursor: pointer;
    color: red;
} 

.commentCardLessonInfoDivTexts{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-top: 10px;
}

.commentCardLessonThumbnail{
    width: 180px;
    height: 100px;
}

.commentDateSpan{
    font-size: 12px;
    margin-left: 15px;
    font-style: italic;
}

.ratingReplyContainer{
    display: flex;
    flex-direction: column;
}

.ratingReplyText{
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.ratingReplyInnerContainer{
    display: flex;
    flex-direction: column;
}

.ratingReplyInnerContainer .fas.fa-trash{
    color: red;
    cursor: pointer;
}

.showCommentText{
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
}

.replyUserName{
    font-weight: 600;
    text-transform: capitalize;
}