.studentAssignmentDetails_editor_container {
  padding: 0px;
  // margin-top: 30px;
}

.approveDenyButtonsDiv {
  float: right;
  display: flex;
  margin-left: auto;
  margin-top: 15px;
}


/* Student Details New Top Section */

.studentDetailsTopContainer {
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  margin: 2px;
  border-radius: 10px;
  width: 100%;
  background-color: indigo;
  color: whitesmoke;

}

.studentDetailImage {
  border-radius: 50%;
  max-width: 150px;
  margin-bottom: 15px;
  object-fit: contain;
}

.studentDetailsImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.studentDetailsImageContainer span {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}


.studentDetailsTopInfoContainer {
  margin-left: 20px;
}

.userIdInfoDiv {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
}

.studentDetailsInfoTopButtons {
  margin-bottom: 10px;
  border-bottom: 2px solid black;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;

}

.studentTopButtonsDiv {
  margin-left: 100px;
}

.studentTopButtonsDiv button {
  margin-right: 10px;
  font-size: 15px;
  border: none;
  padding: 10px 15px;
  background: #2ec2e2;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
}

.referralListButton {
  margin-right: 10px;
  font-size: 15px;
  border: none;
  padding: 10px 15px;
  background: #2ec2e2;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
}

.userIdInfoDiv span:first-child {
  color: red;
  font-size: 18px;
  font-weight: bold;
}

.userIdInfoDiv span:last-child {
  color: indigo;
  font-size: 20px;
  font-weight: bold;
}

.studentDetailsTopInfoInnerContainer1 {
  display: flex;
  gap: 150px;
}

.studentDetailsTopInfoInnerContainer1 ul {
  list-style: none;
  justify-content: flex-start;
}

.studentDetailsTopInfoInnerContainer1 ul li {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.studentDetailsTopInfoInnerContainer1 ul li span {
  color: white;
}

.date {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;

  h5 {
    font-size: 15px;
  }

  .datepick {
    width: 15%;
  }
}

.statistics-section {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: .5%;

  .box-card {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-radius: 5px;
    //needed box with same shadow in all side
    box-shadow: 1px 1px 2px 2px lightgrey;
    width: 24%;
    background-color: #449cea;

    .heading {
      font-size: 14px;
    }

    .content {
      font-size: 16px;
    }
  }
}

.top-user-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px;

  h1 {
    font-weight: bold;
  }
}

.top-user {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1%;
  row-gap: 10px;

  .users {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    padding: 5px;
    border-radius: 5px;
    //needed box with same shadow in all side
    box-shadow: 1px 1px 2px 2px #f5f1f1;
    width: 19%;
    background-color: lightgrey;

    .user-detail {
      h4 {
        margin: 2px;
      }
    }
  }
}

.referral-tab {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  .tab-button {
    cursor: pointer;
    border-radius: 5px;
    width: 10%;
    background-color: white;
    padding: 5px;
    border: 2px solid indigo;
    margin-right: 10px;
  }

  .active {
    background-color: indigo;
    color: white;
    font-weight: bold;
  }
}
.node {
  cursor: pointer;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1.5px;
}

.node text {
  font: 10px sans-serif;
}

.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1.5;
}