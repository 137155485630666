body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.errorContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.errorContainer button{
  width: 20%;
  padding: 10px;
  border: none;
  background-color: #2ec2e2;
  border-radius: 50px;
  color: white;
  font-weight: bold;
  cursor: pointer;

}

.errorTitle{
  font-size: 28px
}

html{
  scroll-behavior: smooth
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ffffff00;
  border-radius: 0.375rem;
  box-shadow: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-control:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0);
  outline: 0;
  box-shadow: none;
}
.form-control:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0);
  outline: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.inside-table {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0);
  outline: 0;
  /* box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02); */
}
.inside-table {
  display: block;
  width: 100%;
  height: 35px;
  padding: 5px;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  /*background-color: #fff;*/
  background-clip: padding-box;
  border:none;
  borderBottom: 1px solid black;
  /*border-radius: 0.375rem;*/
  /*box-shadow: white;*/
  /*box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);*/
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.link-table:hover{
   background-color: #FFFFFF;
}
.link-mtable.MuiIconButton-root:hover {
  background-color: rgb(255, 255, 255);
}

.payment-transaction {
  .title {
    font-weight: bold;
    color: black;
  }
}


.video-container {
  height: auto;
  background-color: #333;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px;
}

.small-video {
  width: 100%;
  max-width: 400px;
  height: 225px;
  background-color: #000;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
}

.big-video video{
  width:100%;
  height:100%;
}

@media screen and (max-width: 768px) {
  .small-video {
      max-width:100%;
      width: 100%;
      position:unset;
      height:auto !important;
      background-color: #000;
      margin-bottom:20px;
  }
  .video-container{
      overflow:hidden;
  }
}

.controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}


.icon-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  z-index:1;
}

.icon-container .fa {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.minimized {
  width: 100%;
  max-width: 600px !important;
  height: 400px !important;
}

.minimize-small-video{
  max-width: 269px !important;
  height: 150px !important;
  right: 4px !important;
}

.dragging {
  cursor: move;
}

.small-video video{
  width:100%;
  height:100%
}
.icon-back{
  background:#4f2bab;
  color:#fff;
  height:40px;
  width:40px;
  border-radius:20px;
  border:none;
  margin-right: 2px;
  cursor: pointer;
}
.red{
  background:red;
}

.blue-back {
  background:#4f2bab;
}


.icon-back-big{
  background: #4f2bab;
  color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: none;
  margin-right: 17px;
  cursor: pointer;
}




@import '~antd/dist/antd.css';
