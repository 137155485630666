.lessonFormThumbnailImage{
    width: 600px;
    height: 300px;
  }

.thumbnail_upload_button{
    margin-bottom: 15px;
}

.thumbnail_upload_button::-webkit-file-upload-button {
    border: none;
    border-radius: 4px;
    background-color: #0abde3 ;
    color: white;
    cursor: pointer;
}

.thumbnailContainer{
    position: relative;
}

.thumbnailContainer .far.fa-times-circle{
    font-size: 25px;
    position: absolute;
    right: 280px;
    cursor: pointer;
}

.thumbnailContainerAddLesson{
    position: relative;
}

.thumbnailContainerAddLesson .far.fa-times-circle{
    font-size: 25px;
    position: absolute;
    right: 290px;
    cursor: pointer;
}

.thumbnailContainerAssignment{
    position: relative;
}

.thumbnailContainerAssignment .far.fa-times-circle{
    font-size: 25px;
    position: absolute;
    right: 120px;
    cursor: pointer;
}

.addRemarksContainerNew{
    margin-top: 20px;
    z-index: 1000;
}

.addRemarksContainer{
    margin-top: 20px;
    max-width: 1000px;
    z-index: 999999;
}

.addNewLessonButton{
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid grey;
    background-color:rgb(103, 114, 229) ;
    color: white;
    cursor: pointer;
}

.toggleViewButton{
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid grey;
    background-color: #34495e;
    color: white;
    cursor: pointer;
    padding: 5px;

}

.copyPasteButtonDiv{
    display: flex;
}

.copyPasteButtonDiv button{
    margin-right: 10px;
}

.pasteButton{
    width: 55px !important;
}

.modal-dialog.modal-dialog-centered.lessonUpdate{
    width: 1000px !important;
}

.courseSelectContainer{
    margin-top: 130px;
}

.lessonEditTitleDiv{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.lessonEditTitleDiv button{
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

