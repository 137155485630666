/* Announcement field Styles --------------------------- */

.modal-open{
    overflow: none !important;
}

.announcementFieldContainer{
    padding: 15px 40px;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
}

.fas.fa-image{
    font-size: 30px;
    margin-right: auto;
    color: #30336b;
    cursor: pointer;
}

.announcementImageContainer{
    display: flex;
    justify-content: center;
}

.announcementImageContainer img{
    max-width: 600px;
}

.fas.fa-times-circle{
    margin-left: 15px;
    font-size: 20px;
    color: red;
    cursor: pointer;
}


/* Post Detail Styles --------------------------- */

.seeMoreSpan{
    cursor: pointer;
    font-weight: bold;
    margin-left: 8px;
}


.communityPostContainer{
    display: flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    margin-bottom: 15px;
}

.communityPostUsername{
    font-weight: bold;
    cursor: pointer;
}


.communityPostTopDiv{
    display: flex;
    margin-bottom:15px;
    position: relative;
}

.postMenuOptions{
    position: absolute;
    right: 5px;
    top:15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding:10px;
    background: white;
}

.postMenuOptions ul{
    list-style: none;
    padding-left: 0px;
    padding-bottom: 0px;
    cursor: pointer;
    margin-bottom: 0px;
}

.postMenuOptions ul li{
    margin-top: 5px;
    color: #746b6b;
}

.postMenuOptions ul li:hover{
    color: #000000;
}


.pinIconDiv .fas.fa-ellipsis-h{
    margin-left: 15px;
    cursor: pointer;
}

.fas.fa-ellipsis-h{
    margin-left: auto;
    cursor: pointer;
}

.pinIconDiv{
    margin-left: auto;
}

.fas.fa-thumbtack{
    margin-left: auto;
    color: #5e72e4;
}

.communityPostUserImageDiv{
    width: 30px;
}

.communityPostUserImageDiv img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
}

.communityPostNameTimeDiv{
    display: flex;
    flex-direction: column;
    margin-left: 15px;

}

.communityPostTextArea{

}

.editPostTextArea{
    width: 100%;
    height: 150px;
}

.editPostImage{
    max-height: 400px;
}

.editPostImageContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
}

.editPostImageContainer img{
    margin-bottom: 10px;
}



.communityPostImageDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
    margin: 10px 0px;

}

.sharedPostDiv{
    margin-top: 10px;
}

.communityPostImageDiv img{
    max-width: 600px;
}

.likeCommentShareDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.likeCommentShareDiv span{
    font-weight: 600;
    cursor: pointer;
}

.communityPostCommentsContainer{
    margin-top: 15px;
    border-top: 1px solid black;
    padding-top: 10px;
}


/* Comment Component styling */

.communityPostCommentDiv{
    position: relative;
    margin-bottom: 10px;
}

.communityPostCommentTopDiv{
display: flex;
}

.communityPostCommentImageDiv{
width: 30px;
}

.communityPostCommentImageDiv img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.communityPostCommentInfo{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.communityPostCommentInfo span:first-child{
    font-weight: bold;
    cursor: pointer;
}

.communityPostCommentInfo span:last-child{
    font-size: 10px;
}

.communityPostCommentText{
    padding-left: 40px;
}

.communityPostCommentRepliesDiv{
    padding-left: 30px;
    margin-top: 5px;
}

.commentMenuOptions{
    position: absolute;
    right: 5px;
    top:15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding:10px;
    background: white;
}

.commentMenuOptions ul{
    list-style: none;
    padding-left: 0px;
    padding-bottom: 0px;
    cursor: pointer;
    margin-bottom: 0px ;
    
}

.commentMenuOptions ul li{
    padding-bottom: 0px;
    color: #746b6b;
}

.commentMenuOptions ul li:hover{
    color: #000000;
}