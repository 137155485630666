/* CustomPopup.scss */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    display: none; 
    width: fit-content;
  }
  
  .popup-content {
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    background-color: white;
    border-radius: 35px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1060;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 400px;
    height: 70px;
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  

  .tittle-call{
    margin: 0;
    color: #4f2bab;
    font-size: 19px;
  }
  .incoming-call-cuntrol-btn{
    display: flex;
    gap: 10px;
    margin: 0;
  }

      