

.paymentPageContainer{
    margin-top: 120px;
    min-height: 400px;
}

.paymentSwitchButtonDiv{

}

.paymentButtonNew{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
    color:#2c3e50 ;   
}

.paymentButtonNew.active{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #2ec2e2;
}

.paymentButtonHistory{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
    color:#2c3e50 ; 

}

.paymentButtonHistory.active{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #2ec2e2;

}

.paymentTableContainer{
    margin-top: 25px;
}

.paymentDetailsDiv{
    display: flex;
}

.modal-dialog-centered.paymentDetailModal{
    width: 900px !important; 
}