.reviewCommentContainer{
    margin-top: 120px;
    min-height: 400px;
}


.reviewCommentButtonDiv{
    display: flex;
    margin-top: 20px;
}


.reviewCommentButton_published{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
    color:#2c3e50 ;   
}

.reviewCommentButton_published.active{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #2ec2e2;
}

.reviewCommentButton_forReview{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
    color:#2c3e50 ; 

}

.reviewCommentButton_forReview.active{
    border: none;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 50px;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #2ec2e2;

}

.reviewComment_commentsContainer{
    margin-top: 30px;

}

.editCommentInput{
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
    width: 100%;
}

.noCommentsSpan{
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.reviewRatingHeadTextContainer{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.ratingCountNumber{
   font-weight: 700; 
   font-size: 18px;
}

.nextPreviousDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0px 20px;
    margin-bottom: 15px;
}



