.table {
  .avatar-group .avatar
  {
    margin-right: 4px;
  }
  .badge i{
    margin-right: 10px;
  }
}
.link-mtable .MuiIconButton-root:hover {
  background-color: initial; /* Set the background-color property to its default value */
}
.link-mtable .MuiIconButton-root:focus-visible {
  background-color: #FFFFFF;
  outline: none;
}
.link-mtable .MuiIconButton-colorInherit{
  background-color: #FFFFFF;
}
