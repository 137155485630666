.call-window {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	z-index: -1;
	@include transition(opacity 0.5s);
	@include bg-gradient(top, #074055 0%, #030D10 100%);
	
	&.active {
		opacity: 1;
		z-index: auto;
		
		.video-control {
			z-index: auto;
			@include animation(in-fadeout 3s ease);
		}
	}
	
	.video-control {
		position: absolute;
		bottom: 20px;
		height: 72px;
		width: 100%;
		text-align: center;
		opacity: 0;
		z-index: -1;
		@include transition(opacity 0.5s);
		
		
		&:hover {
			opacity: 1;
		}
	}
	
	video {
		position: absolute;
	}
	
	#localVideo {
		bottom: 0;
		left: 0;
		width: 20%;
		height: 20%;
	}
	
	#peerVideo {
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@include keyframes(in-fadeout) {
	0% {opacity: 1}
	75% {opacity: 1}
	100% {opacity: 0}
}

.video-control, .call-modal {
	.btn-action {
		$height: 50px;
		height: $height;
		width: $height;
		line-height: $height;
		margin: 0px 8px;
		font-size: 1.4em;
		text-align: center;
		border-radius: 50%;
		cursor: pointer;
		transition-duration: 0.25s;
		
		&:hover {
			opacity: 0.8;	
		}
		
		&.hangup {
			background-color: $red;
			@include transform(rotate(135deg));
		}
		
		&:not(.hangup) {
			background-color: $blue;
			
			&.disabled {
				background-color: $red;
			}
		}
	}
}