@import "mixin";

html, body {
	.no-animation {
		* {
			@include no-animation();
		}
	}
}

input {
	outline: none;
}