.quizOptionsDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;
 
}

.quizOptionsDiv input[type="text"]{
    margin-top: 8px;
    width: 100%;

}

.quizOptionsDiv input[type="checkbox"]{
    margin-top: 8px;
    margin-left: 10px;

}


.quizQuestionDiv{
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid rgb(151, 146, 146);
    border-radius: 4px;
}

.quizAnswerContainer{
    display: flex;
    align-items: center;
    

}

.far.fa-trash-alt{
    margin-left: 10px;
    margin-top: 6px;
    cursor: pointer;
    margin-right: 40px;
}

.fas.fa-times{
    cursor: pointer;

}

.quizQuestionTitleContainer{
    display: flex;
    justify-content: space-between;
}